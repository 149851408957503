* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
}

body {
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;

  /* background-color: #001029; */
}

a:link,
a:visited,
a:hover,
a:active {
  color: #444;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* font-size: 18px; */
  font-weight: 400;
  word-break: break-word;
  color: #000;
}

h1 {
  font-weight: 400;
  font-size: 24px;
  color: #000;
  word-break: break-word;
  line-height: 40px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
